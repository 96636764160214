import { Grid, Stack, AppBar, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import CardTitle from './CardTitle';
import { useGetDraftQuery, useUpdateDraftMutation } from '../../../api/physicianDashboardSlice';
import { useState } from 'react';
import CompleteModal from '../CompleteModal';
import useAnalytics from '../../Hooks/useAnalytics';
import CardReviewTabs from './CardReviewTabs';
import Optimization from './Optimizations/Optimization';
import CategoryView from './CategoryView';
import CategoryList from './CategoryList';
import ItemInfo from './ItemInfo/ItemInfo';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/system';
import { CelebrationOutlined } from '@mui/icons-material';
import InvalidToken from '../InvalidToken';

function CardReview() {
    const { draftId } = useParams();
    const analytics = useAnalytics();
    const { state } = useLocation();
    const { physician } = state ?? {};
    const theme = useTheme();
    const [isShowingCompleteModal, setIsShowingCompleteModal] = useState(false);
    const [isShowingCategoryView, setIsShowingCategoryView] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('');
    const [highlightedItem, setHighlightedItem] = useState();
    const [editingItem, setEditingItem] = useState();
    const [oldSavings, setOldSavings] = useState(0);
    const [currentSavings, setCurrentSavings] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        analytics.track('Page Loaded', {
            sector: 'Physician Dashboard',
            name: 'Single Card View',
            data: {
                cardId: draftId
            }
        });
        window.scrollTo(0, 0);
    }, []);

    const {
        data: draft = {},
        isLoading: draftLoading,
        isSuccess: draftLoaded,
        error
    } = useGetDraftQuery(draftId);

    const optimizations = useMemo(() => {
        var opts = [];
        if (draftLoaded) {
            Object.keys(draft.recommendations).forEach((category) => {
                draft.recommendations[category].forEach((recommendation) => {
                    // We want to filter out swaps here since we show them elsewhere in the dashboard.
                    if (recommendation.type !== 'swap' && recommendation.type !== 'compliance') {
                        opts.push(recommendation);
                    }
                });
            });
        }

        if (opts.length === 0 && draftLoaded) {
            setSelectedTab(1);  // If there are no optimizations once the draft is loaded, default to the All Card Items tab.
        }

        return opts.sort((a, b) => {
            return Math.abs(b['recommendation_value']) - Math.abs(a['recommendation_value']);
        });
    }, [draft.recommendations]);

    const [
        submitChanges,
        { isLoading: isSubmittingChanges },
    ] = useUpdateDraftMutation();

    const handleSubmitChanges = async () => {
        analytics.track('Button Clicked', {
            label: 'Review Complete - Submit Changes',
            sector: 'Physician Dashboard',
            location: 'Single Card View'
        })
        await submitChanges({
            draftId: draft.id,
            status: 'submitted'
        });
        setIsShowingCompleteModal(true);
    }

    function updateCardSavings(newSavings){
        setOldSavings(currentSavings);
        setCurrentSavings(newSavings);
    }

    function openItemView(item) {
        setIsShowingCategoryView(false);
        setEditingItem(item);
        sessionStorage.setItem('cardReviewScrollPos', window.scrollY);
    }

    function closeCategoryView() {
        setIsShowingCategoryView(false);
        setCurrentCategory('');
        setHighlightedItem(null);

        setTimeout(() => {
            window.scrollBy(0, sessionStorage.getItem('cardReviewScrollPos'));
            sessionStorage.removeItem('cardReviewScrollPos');
        }, 2);
    }

    function closeItemView() {
        setEditingItem(null);
        if (currentCategory) {
            setIsShowingCategoryView(true);
            setTimeout(() => {
                window.scrollBy(0, sessionStorage.getItem('categoryScrollPos'));
                sessionStorage.removeItem('categoryScrollPos');
            }, 2);
        } else {
            setTimeout(() => {
                window.scrollBy(0, sessionStorage.getItem('cardReviewScrollPos'));
                sessionStorage.removeItem('cardReviewScrollPos');
            }, 2);
        }
    }

    function openCategory(category, highlightedItem) {
        setCurrentCategory(category);
        setIsShowingCategoryView(true);
        setHighlightedItem(highlightedItem);
        sessionStorage.setItem('cardReviewScrollPos', window.scrollY);
    }

    if (!physician) {
        return <Navigate to="/physicianDashboard/auth" />;
    }

    if(error){
        return(
            <InvalidToken resendButton={true} />
        )
    }

    return (
        <Grid container sx={{ backgroundColor: '#EDEDED' }}>
            <Grid item md={3} />
            <Grid item xs={12} md={6}>
                { !editingItem && draftLoaded &&
                    <CardTitle 
                        physician={physician}
                        draft={draft} 
                        isLoading={draftLoading} 
                        oldSavings={oldSavings} 
                        currentSavings={currentSavings} 
                        updateCardSavings={updateCardSavings}
                    /> 
                }
                { !editingItem && !currentCategory &&
                    <Grid item xs={12}>
                        <CardReviewTabs 
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    </Grid>
                }
                { !currentCategory && !editingItem && draftLoaded &&
                    <Stack sx={{ backgroundColor: '#EDEDED' }}>
                        { selectedTab === 0 && optimizations.length > 0 &&
                            <Stack spacing={2} py={3} px={1.5}>
                                { optimizations.map((optimization) => (
                                    <Optimization 
                                        key={optimization.id} 
                                        recommendation={optimization}
                                        draft={draft} 
                                        item={
                                            draft.items[optimization.item.category] !== undefined ? 
                                                draft.items[optimization.item.category].filter((item) => item.id === optimization.item.id)[0] :
                                                null
                                        } 
                                        categoryCount={
                                            draft.items[optimization.item.category] !== undefined ?
                                                draft.items[optimization.item.category].length :
                                                0
                                        }
                                        setCurrentCategory={setCurrentCategory}
                                        setIsShowingCategoryView={setIsShowingCategoryView}
                                        setHighlightedItem={setHighlightedItem}
                                        openItemView={openItemView}
                                        closeItemView={closeItemView}
                                    />
                                ))}
                            </Stack>
                        }
                        { selectedTab === 0 && optimizations.length === 0 &&
                            <Stack spacing={2} pt={4} px={1.5} alignItems='center' sx={{ color: `${theme.palette.subtext.main}` }}>
                                <CelebrationOutlined fontSize='large' />
                                <Typography variant='heavy' sx={{ fontSize: '16px' }}>No Optimizations Here!</Typography>
                            </Stack>
                        }
                        { selectedTab === 1 && 
                            <CategoryList
                                draft={draft}
                                setCurrentCategory={setCurrentCategory}
                                setIsShowingCategoryView={setIsShowingCategoryView}
                            />
                        }
                        <AppBar position='sticky' elevation={10} sx={{ marginTop: 5, paddingLeft: 3, paddingRight: 3, bottom: 16, backgroundColor: '#FFFFFF0F', alignSelf: 'center', boxShadow: 0 }}>
                            <LoadingButton onClick={handleSubmitChanges} loading={isSubmittingChanges}  position='relative' color = 'inherit' variant='extended'  sx={{ 
                                width: '100%', alignSelf: 'center', borderRadius: '4px', opacity: 1,  boxShadow: 1, 
                                bgcolor: `${theme.palette.blue.main}`,
                                '&:hover': {
                                    bgcolor: `${theme.palette.blue.main}`}
                            }}>
                                Review Complete - Submit Changes
                            </LoadingButton> 
                        </AppBar>
                    </Stack>
                }
                { isShowingCategoryView &&
                    <CategoryView
                        open={isShowingCategoryView}
                        setOpen={setIsShowingCategoryView}
                        setCurrentCategory={setCurrentCategory}
                        category={currentCategory}
                        draft={draft}           
                        highlightedItem={highlightedItem}
                        setHighlightedItem={setHighlightedItem}
                        openItemView={openItemView}
                        closeItemView={closeItemView}
                        closeCategoryView={closeCategoryView}
                        selectedTab={selectedTab}
                    />
                }
                { editingItem && !isShowingCategoryView &&
                    <ItemInfo 
                        item={editingItem}
                        draft={draft}
                        closeItemView={closeItemView}
                        openCategory={openCategory}
                        physician={physician}
                    />
                }
            </Grid>
            <Grid item md={3} />
            { isShowingCompleteModal &&
                <CompleteModal 
                    open={isShowingCompleteModal}
                    setOpen={setIsShowingCompleteModal}
                    physician={physician}
                    campaignId={draft.campaign_id}
                    variant='standard'
                />
            }
        </Grid>
    );
}

export default CardReview;
